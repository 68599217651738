<template>
    <div class="preview_area" v-if="modelValue" :class="{active : modelValue}" :style="`
        --var-card-bg-color: ${card_bg_color ? card_bg_color : '#fff4e2'};
        --var-card-text-color: ${card_text_color ? card_text_color : '#121525'};
        --var-card-elm-color: ${card_elm_color ? card_elm_color : '#2f7eed'};
        --var-card-elm-fade-color: ${card_elm_color ? card_elm_color+30 : '#2f7eed30'};
        `">
        <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
        <div class="container">
            <div v-for="(section, s) in categories" :key="s">
                <div class="section_title mt-5">
                    <h1>{{section.group_title}} Daily</h1>
                    <h4>Please provide information about {{section.group_title.toLowerCase()}} on a daily basis.</h4>
                </div>
                <ul class="card_list">
                    <li v-for="(category, c) in section.trackers" :key="c">
                        <div class="water_card">
                            <div class="top_area">
                                <img v-if="category.img" :src="require(`@/assets/images/${category.img}`)" :alt="category.name">
                                <div class="main_title">{{category.title}}</div>
                                <div class="sub_text">{{category.subtext}}</div>
                                <div class="ratings" v-if="category.unit && category.unit.value == 'Ratings' && !category.area_categories && category.value >= 0">
                                    <span v-for="star in category.max_val" :key="star"><i class="fas fa-star" :class="{'active' : star <= category.value}"></i></span>
                                </div>
                                <div class="result" v-if="!category.sub_items && !category.area_categories && !category.variants && !category.choices && category.unit && category.unit.value !== 'Ratings'">
                                    {{ category.unit.value == 'oz' ? category.value * 8 : category.value}} <span v-if="category.unit">{{ category.unit.value == 'Number' ? '' : category.unit.value }}</span>
                                </div>
                                <div class="result_list" v-if="category.sub_items">
                                    <ul>
                                        <li v-for="(item, i) in category.sub_items" :key="i">
                                            <label>
                                                {{ item.name }}
                                            </label>
                                            <div class="item_result" v-if="item.value >= 0">
                                                {{ item.value}} <span v-if="category.unit">{{category.unit.value}}</span>
                                            </div>
                                            <div class="item_result" v-if="item.duration">
                                                {{ item.duration.min < 10 ? 0 + '' + item.duration.min : item.duration.min }} : {{ item.duration.sec < 10 ? 0 + '' + item.duration.sec : item.duration.sec }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="single_item" v-if="category.choices && category.selected_item">{{ category.selected_item.name }}</div>
                                <div class="multiple_items" v-if="category.variants && category.selected_items.length"><span v-for="(item, i) in category.selected_items" :key="i">{{ item }}</span></div>
                            </div>
                            <div class="bottom_area">
                                <div class="range_slider" v-if="category.value >= 0 && category.low_text && category.high_text && category.unit.value !== 'Number' && !category.area_categories">
                                    <span :style="`width: ${(100 / ((category.max_val ? category.max_val : 10) - (category.min_val ? category.min_val : 0))) * (category.value - (category.min_val ? category.min_val : 0))}%`">
                                        <label>{{category.value}}</label>
                                    </span>
                                    <input ref="input" class="slider" v-model="category.value" type="range" :min="category.min_val ? category.min_val : 0" :max="category.max_val ? category.max_val : 10" step="1">
                                    <ul class="range_label">
                                        <li>{{category.low_text}}</li>
                                        <li>{{category.high_text}}</li>
                                    </ul>
                                </div>
                                <div class="input_section" v-if="category.value >= 0 && category.low_text && category.high_text && category.unit.value == 'Number'">
                                    <div class="field_box">
                                        <div class="field_area">
                                            <input type="number" :min="category.min_val ? category.min_val : 0" :max="category.unit.value == '%' ? 100 : 999999" v-model="category.value" placeholder="0">
                                            <span class="unit" v-if="category.unit && category.unit.value !== 'Number'">{{ category.unit.value }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input_section" v-if="category.value >= 0 && !category.low_text && !category.high_text">
                                    <div class="field_box">
                                        <div class="field_area">
                                            <input type="number" :min="category.min_val ? category.min_val : 0" :max="category.unit.value == '%' ? 100 : 999999" v-model="category.value" placeholder="0">
                                            <span class="unit" v-if="category.unit">{{ category.unit.value }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input_section" v-if="category.sub_items">
                                    <template v-for="(item, i) in category.sub_items" :key="i">
                                        <div class="field_box" v-if="item.status && item.value >= 0">
                                            <label>{{ item.name }}</label>
                                            <div class="field_area">
                                                <input type="number" min="0" v-model="item.value" placeholder="0">
                                                <span class="unit" v-if="category.sub_items.length <= 2 && category.unit">{{ category.unit.value }}</span>
                                            </div>
                                        </div>
                                        <div class="input_section" v-if="item.status && item.duration">
                                            <label>Duration</label>
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="59" v-model="item.duration.min" placeholder="0">
                                                    <span class="unit">min</span>
                                                </div>
                                            </div>
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="59" v-model="item.duration.sec" placeholder="0">
                                                    <span class="unit">sec</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <table class="table_list" v-if="category.title == 'Workouts' && category.variants">
                                    <template v-for="(variant, v) in category.variants" :key="v">
                                        <tr v-if="variant.status">
                                            <td>
                                                <label :for="`vitamin-${variant.name}`" class="checkbox" @click="selectTag(variant.name, category)">
                                                    <span :class="{'checked' : category.selected_items.includes(variant.name)}"><i class="fas fa-check"></i></span>
                                                </label>
                                            </td>
                                            <th>{{variant.name}}</th>
                                            <td>
                                                <div class="field_area">
                                                    <input type="number" min="0" max="59" v-model="variant.value" placeholder="0">
                                                    <span class="unit">{{category.unit.value}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                                <ul class="check_list" v-if="category.title !== 'Workouts' && category.variants">
                                    <template v-for="(variant, v) in category.variants" :key="v">
                                        <li v-if="variant.status">
                                            <label :for="`vitamin-${variant.name}`" class="checkbox" @click="selectTag(variant.name, category)">
                                                <span :class="{'checked' : category.selected_items.includes(variant.name)}"><i class="fas fa-check"></i></span>
                                                <p>{{variant.name}}</p>
                                            </label>
                                        </li>
                                    </template>
                                </ul>
                                <div v-if="category.area_categories">
                                    <div class="pain_area">
                                        <ul class="tabs">
                                            <li v-for="(area, a) in category.area_categories" :key="a" @click="card_tabs = a" :class="{'active' : card_tabs == a}">{{ area.type }}</li>
                                        </ul>
                                        <div class="area_section" v-for="(area, a) in category.area_categories" :key="a" v-show="card_tabs == a">
                                            <template v-for="(variant, v) in area.variants" :key="v">
                                                <div class="pain_item" v-if="variant.status">
                                                    <label :for="`pain-${variant.name}`" class="checkbox" @click="selectPain(variant, category)">
                                                        <span :class="{'checked' : category.selected_items.findIndex((att) => att.name == variant.name) !== -1}"><i class="fas fa-check"></i></span>
                                                        <p>{{variant.name}}</p>
                                                    </label>
                                                    <div class="pain_details" v-if="category.selected_items.findIndex((att) => att.name == variant.name) !== -1">
                                                        <div class="select_box" @click="openSelectBox($event)">
                                                            <div class="tag_wpr" v-if="variant.select_position.length">
                                                                <span class="tag" v-for="(type, y) in variant.select_position" :key="y">{{ type }}</span>
                                                            </div>
                                                            <p v-else>Select anatomical positions</p>
                                                            <i class="fas fa-caret-down"></i>
                                                            <div class="dropdown_wpr">
                                                                <ul>
                                                                    <li v-for="(position, p) in category.positions" :key="p" @click="selectPainPositons($event, position, variant)">
                                                                        <span class="checkbox" :class="{'checked' : variant.select_position.includes(position)}"><i class="fas fa-check"></i></span>
                                                                        {{ position }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="range_slider" v-if="variant.select_position">
                                                            <span :style="`width: ${(100 / ((category.max_val ? category.max_val : 10) - (category.min_val ? category.min_val : 0))) * (variant.value - (category.min_val ? category.min_val : 0))}%`">
                                                                <label>{{variant.value}}</label>
                                                            </span>
                                                            <input ref="input" class="slider" v-model="variant.value" type="range" :min="category.min_val ? category.min_val : 0" :max="category.max_val ? category.max_val : 10" step="1">
                                                            <ul class="range_label">
                                                                <li>{{category.level_1}}</li>
                                                                <li>{{category.level_2}}</li>
                                                                <li>{{category.level_3}}</li>
                                                                <li>{{category.level_4}}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="result_info" v-if="variant.select_position.length && variant.value">
                                                        <span v-for="(type, y) in variant.select_position" :key="y">{{ y == 0 ? type.replace(/ *\([^)]*\) */g, "") : type.replace(/ *\([^)]*\) */g, "").toLowerCase()}}&nbsp;</span>{{variant.name.toLowerCase()}} pain with level {{category.unit.value == '%' ? `${variant.value}%` : `${variant.value}/${category.max_val}`}} severity.
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <ul class="radio_list" v-if="category.choices">
                                    <template v-for="(choice, c) in category.choices" :key="c">
                                        <li>
                                            <label :for="`${choice.name}`" class="radio_box" :class="{'active' : category.selected_item.id == choice.id}" @click="category.selected_item = choice">
                                                <img :src="require(`@/assets/images/${choice.img}`)" :alt="choice.name">
                                                <h5>{{choice.name}}</h5>
                                                <p>{{choice.desc}}</p>
                                            </label>
                                        </li>
                                    </template>
                                </ul>
                                <div class="notes" v-if="category.has_notes">
                                    <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                    <textarea rows="5" v-model="category.notes" placeholder="Leave your notes here.."></textarea>
                                    <button type="button" class="save_btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="section_title mt-5">
                    <h1>{{section.group_title}} Weekly</h1>
                    <h4>Please provide information about {{section.group_title.toLowerCase()}} on a weekly basis.</h4>
                </div>
                <div class="table_list mt-5">
                    <table>
                        <thead>
                            <tr>
                                <th>Tracker Name</th>
                                <th v-for="(date, d) in dates" :key="d">{{ date }}</th>
                            </tr>
                        </thead>
                        <tbody v-for="(tracker, t) in section.trackers" :key="t" :class="{'accord' : tracker.sub_items || tracker.variants}">
                            <template v-if="tracker.sub_items">
                                <tr @click="open_subitems($event)">
                                    <td class="tracker">
                                        <div class="tracker_title">
                                            <img :src="require(`@/assets/images/${tracker.img}`)"/>
                                            <h4>{{tracker.name}}</h4>
                                            <button type="button" class="toggle_btn"><i class="fas fa-chevron-down"></i></button>
                                        </div>
                                    </td>
                                    <td v-for="date in dates.length" :key="date"></td>
                                </tr>
                                <tr v-for="(sub_tracker, s) in tracker.sub_items" :key="s" class="subs">
                                    <td class="tracker">
                                        <div class="tracker_title">
                                            <img :src="require(`@/assets/images/${sub_tracker.img}`)"/>
                                            {{sub_tracker.name}}
                                        </div>
                                    </td>
                                    <td v-for="date in dates.length" :key="date">
                                        <div class="field_area">
                                            <input type="number" min="0" max="100" placeholder="0">
                                            <span class="unit">{{tracker.unit.value}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="tracker.variants">
                                <tr @click="open_subitems($event)">
                                    <td class="tracker">
                                        <div class="tracker_title">
                                            <img :src="require(`@/assets/images/${tracker.img}`)"/>
                                            <h4>
                                                {{tracker.name}}
                                            </h4>
                                            <button type="button" class="toggle_btn"><i class="fas fa-chevron-down"></i></button>
                                        </div>
                                    </td>
                                    <td :colspan="dates.length"><h5>Select all {{tracker.name}} taken</h5></td>
                                </tr>
                                <tr v-for="(varient, v) in tracker.variants" :key="v" class="subs">
                                    <td class="tracker">
                                        <div class="tracker_title">
                                            <h4>{{varient.name}}</h4>
                                        </div>
                                    </td>
                                    <td v-for="(date, d) in dates.length" :key="d">
                                        <label :for="`${varient.name}-${v}${d}`" class="checkbox">
                                            <input type="checkbox" :id="`${varient.name}-${v}${d}`" placeholder="0" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Yes</p>
                                        </label>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td class="tracker">
                                        <div class="tracker_title">
                                            <img :src="require(`@/assets/images/${tracker.img}`)"/>
                                            <h4>{{tracker.name}}</h4>
                                        </div>
                                    </td>
                                    <td v-for="date in dates.length" :key="date">
                                        <div class="field_area">
                                            <input type="number" min="0" max="100" placeholder="0">
                                            <span class="unit">{{tracker.unit.value}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'Progress Tables',
    data(){
        return{
            card_bg_color: '#fff4e2',
            card_icon_color: '#2c3e50',
            card_text_color: '#121525',
            card_elm_color: '#2f7eed',
            dates:[
                // 'Sep 24',
                // 'Sep 25',
                // 'Sep 26',
                // 'Sep 27',
                // 'Sep 28',
                // 'Sep 29',
                // 'Sep 30',
                // 'Oct 1',
                // 'Oct 2',
                // 'Oct 3',
                // 'Oct 4',
                // 'Oct 5',
                // 'Oct 6',
                // 'Oct 7',
                // 'Oct 8',
                // 'Oct 9',
                'Oct 10',
                'Oct 11',
                'Oct 12',
                'Oct 13',
                'Oct 14',
                'Oct 15',
                'Oct 16',
                // 'Oct 17',
                // 'Oct 18',
                // 'Oct 19',
                // 'Oct 20',
                // 'Oct 21',
                // 'Oct 22',
                // 'Oct 23',
            ],
            categories:[
                {
                    group_title: 'Nutrition',
                    trackers:[
                        {
                            name: 'Water',
                            img: 'water.svg',
                            title: 'Water',
                            subtext: 'How much water did you drink?',
                            unit: {
                                mode: 'single',
                                value: 'oz',
                                options: [
                                    { value: 'oz', label: 'Ounce' },
                                    { value: 'ml', label: 'Milliliters' }
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Calories Consumed',
                            img: 'calories-consume.svg',
                            title: 'Calories Consumed',
                            subtext: 'How many calories have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'kcal',
                                options: [
                                    { value: 'kcal', label: 'kcal' }
                                ],
                            },
                            value: 0,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Fruits',
                            img: 'fruits.svg',
                            title: 'Fruits',
                            subtext: 'How much fruits have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'pcs',
                                options: [
                                    { value: 'pcs', label: 'Pieces' },
                                    { value: 'servings', label: 'Servings' },
                                    { value: 'cups', label: 'Cups' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'oz', label: 'Ounce' },
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Vegetables',
                            img: 'vegetables.svg',
                            title: 'Vegetables',
                            subtext: 'How much vegetables have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'pcs',
                                options: [
                                    { value: 'pcs', label: 'Pieces' },
                                    { value: 'servings', label: 'Servings' },
                                    { value: 'cups', label: 'Cups' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'oz', label: 'Ounce' },
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Macros',
                            img: 'macros.png',
                            title: 'Macros',
                            subtext: 'How much fat, protein, and carbs have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'fists', label: 'Fists' },
                                    { value: 'palms', label: 'Palms' },
                                    { value: 'thumbs', label: 'Thumbs' },
                                ],
                            },
                            sub_items:[
                                {
                                    name: 'Fat',
                                    img: 'fat.svg',
                                    status: 1,
                                    value: 1,
                                },
                                {
                                    name: 'Protein',
                                    img: 'protein.svg',
                                    status: 1,
                                    value: 0,
                                },
                                {
                                    name: 'Carbs',
                                    img: 'carbohydrates.svg',
                                    status: 1,
                                    value: 0,
                                }
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Fat',
                            img: 'fat.svg',
                            title: 'Fat',
                            subtext: 'How much fat have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'fists', label: 'Fists' },
                                    { value: 'palms', label: 'Palms' },
                                    { value: 'thumbs', label: 'Thumbs' },
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Protein',
                            img: 'protein.svg',
                            title: 'Protein',
                            subtext: 'How much protein have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'fists', label: 'Fists' },
                                    { value: 'palms', label: 'Palms' },
                                    { value: 'thumbs', label: 'Thumbs' },
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Carbohydrates',
                            img: 'carbohydrates.svg',
                            title: 'Carbohydrates',
                            subtext: 'How much carbs have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                    { value: 'gm', label: 'Grams' },
                                    { value: 'fists', label: 'Fists' },
                                    { value: 'palms', label: 'Palms' },
                                    { value: 'thumbs', label: 'Thumbs' },
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Sugar',
                            img: 'sugar.png',
                            title: 'Sugar',
                            subtext: 'How much sugar have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: 'gm', label: 'Grams' },
                                ],
                            },
                            value: 0,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Sugar Alcohols',
                            img: 'sugar-alcohol.svg',
                            title: 'Sugar Alcohols',
                            subtext: 'How much sugar alcohol have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: 'gm', label: 'Grams' }
                                ],
                            },
                            value: 0,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Fiber',
                            img: 'fiber.png',
                            title: 'Fiber',
                            subtext: 'How much fiber have you consumed today?',
                            unit: {
                                mode: 'single',
                                value: 'gm',
                                options: [
                                    { value: 'gm', label: 'Grams' }
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 0,
                            low_text: 'Low',
                            high_text: 'High',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Supplements',
                            img: 'supplement.svg',
                            title: 'Supplements',
                            subtext: 'Check the supplements you have consumed today',
                            selected_items: [],
                            variants:[
                                {
                                    name: 'BCAAs',
                                    status: true,
                                },
                                {
                                    name: 'Creatine',
                                    status: true,
                                },
                                {
                                    name: 'Fish Oil',
                                    status: true,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Vitamins',
                            img: 'vitamins.png',
                            title: 'Vitamins',
                            subtext: 'Can you please mark-check all the vitamins you took today?',
                            selected_items: [],
                            variants:[
                                {
                                    name: 'Vitamin A',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B1',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B2',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B3',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B5',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B6',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin B12',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin C',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin D',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin E',
                                    status: true,
                                },
                                {
                                    name: 'Vitamin K',
                                    status: true,
                                },
                                {
                                    name: 'Biotin',
                                    status: true,
                                },
                                {
                                    name: 'Choline',
                                    status: true,
                                },
                                {
                                    name: 'Folate',
                                    status: true,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Minerals',
                            img: 'minerals.png',
                            title: 'Minerals',
                            subtext: 'Check the minerals you have consumed today',
                            selected_items: [],
                            variants:[
                                {
                                    name: 'Calcium',
                                    status: true,
                                },
                                {
                                    name: 'Chromium',
                                    status: true,
                                },
                                {
                                    name: 'Copper',
                                    status: true,
                                },
                                {
                                    name: 'Lodine',
                                    status: true,
                                },
                                {
                                    name: 'Iron',
                                    status: true,
                                },
                                {
                                    name: 'Magnesium',
                                    status: true,
                                },
                                {
                                    name: 'Manganese',
                                    status: true,
                                },
                                {
                                    name: 'Molybdenum',
                                    status: true,
                                },
                                {
                                    name: 'Phosphorus',
                                    status: true,
                                },
                                {
                                    name: 'Potassium',
                                    status: true,
                                },
                                {
                                    name: 'Selenium',
                                    status: true,
                                },
                                {
                                    name: 'Sodium',
                                    status: true,
                                },
                                {
                                    name: 'Zinc',
                                    status: true,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                    ]
                },
                {
                    group_title: 'Exercise',
                    trackers:[
                        {
                            name: 'Readiness',
                            img: 'readiness.svg',
                            title: 'Readiness',
                            subtext: 'What\'s your readiness level?',
                            unit: {
                                mode: 'single',
                                value: 'Ratings',
                                options: [
                                    { value: 'Ratings', label: 'Ratings' },
                                    { value: 'Number', label: 'Number' },
                                    { value: '%', label: 'Percentage' },
                                ],
                            },
                            min_val: 0,
                            max_val: 10,
                            value: 5,
                            low_text: 'Not Really',
                            high_text: 'Fully',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Step Count',
                            img: 'steps.svg',
                            title: 'Step Count',
                            subtext: 'How many steps did you take today?',
                            unit: {
                                mode: 'single',
                                value: 'steps',
                                options: [
                                    { value: 'steps', label: 'Steps' },
                                ],
                            },
                            value: 100,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Distance',
                            img: 'distance.svg',
                            title: 'Distance',
                            subtext: 'How much distance did you cover today?',
                            unit: {
                                mode: 'single',
                                value: 'km',
                                options: [
                                    { value: 'km', label: 'Kilometers' },
                                    { value: 'mi', label: 'Miles' }
                                ],
                            },
                            value: 2,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Workout',
                            img: 'workouts.svg',
                            selected_items: [],
                            title: 'Workouts',
                            subtext: 'Select all the workouts you completed today.',
                            unit: {
                                mode: 'single',
                                value: 'min',
                                options: [
                                    { value: 'hr', label: 'Hour' },
                                    { value: 'min', label: 'Minutes' }
                                ],
                            },
                            variants:[
                                {
                                    name: 'None',
                                    status: true,
                                    value: 10, 
                                },
                                {
                                    name: 'Conditioning',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Strength',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Sport',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Mobility',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Recovery',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Breathwork',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Mindfulness',
                                    status: true,
                                    value: 10,
                                },
                                {
                                    name: 'Other',
                                    status: true,
                                    value: 10,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Blood Pressure',
                            img: 'blood-pressure.svg',
                            title: 'Blood Pressure',
                            subtext: 'What\'s your blood pressure today?',
                            unit: {
                                mode: 'single',
                                value: 'mmHg',
                                options: [
                                    { value: 'mmHg', label: 'mmHg' }
                                ],
                            },
                            sub_items:[
                                {
                                    name: 'Systolic',
                                    img: 'blood-pressure.svg',
                                    status: 1,
                                    value: 0,
                                },
                                {
                                    name: 'Diastolic',
                                    img: 'blood-pressure.svg',
                                    status: 1,
                                    value: 0,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Zones',
                            img: 'zone.svg',
                            title: 'Your Heart Rate Zones',
                            subtext: 'What\'s your heart rate zones?',
                            unit: {
                                mode: 'single',
                                value: '%',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                    { value: 'min', label: 'Minutes' },
                                ],
                            },
                            sub_items:[
                                {
                                    name: 'Fat Loss',
                                    img: 'zone.svg',
                                    status: 1,
                                    value: 50,
                                },
                                {
                                    name: 'Cardio',
                                    img: 'zone.svg',
                                    status: 1,
                                    value: 60,
                                },
                                {
                                    name: 'Peak',
                                    img: 'zone.svg',
                                    status: 1,
                                    value: 70,
                                },
                            ],
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Resting HR',
                            img: 'resting-heart-rate.svg',
                            title: 'Resting Heart Rate',
                            subtext: 'What\'s your resting heart rate today?',
                            unit: {
                                mode: 'single',
                                value: 'bpm',
                                options: [
                                    { value: 'bpm', label: 'bpm' },
                                ],
                            },
                            min_val: 40,
                            max_val: 120,
                            low_text: 'Poor',
                            high_text: 'Fantastic',
                            value: 70,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Calories Burned',
                            img: 'calories.png',
                            title: 'Calories Burned',
                            subtext: 'How many calories have you burned today?',
                            unit: {
                                mode: 'single',
                                value: 'kcal',
                                options: [
                                    { value: 'kcal', label: 'kcal' }
                                ],
                            },
                            value: 0,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'HR Variability',
                            img: 'heart-rate-variability.svg',
                            title: 'HR Variability',
                            subtext: 'What\'s your heart rate variability today?',
                            unit: {
                                mode: 'single',
                                value: 'ms',
                                options: [
                                    { value: 'ms', label: 'Milliseconds' },
                                ],
                            },
                            value: 10,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'O2 Saturation',
                            img: 'oxygen-saturation.svg',
                            title: 'O2 Saturation',
                            subtext: 'What\'s your oxygen saturation today?',
                            unit: {
                                mode: 'single',
                                value: '%',
                                options: [
                                    { value: '%', label: 'Percentage' },
                                ],
                            },
                            min_val: 80,
                            max_val: 100,
                            value: 90,
                            low_text: 'Low',
                            high_text: 'Great',
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'VO2 Max',
                            img: 'vo2-max.svg',
                            title: 'VO2 Max',
                            subtext: 'What\'s your VO2 Max today?',
                            unit: {
                                mode: 'single',
                                value: 'ml/kg/min',
                                options: [
                                    { value: 'ml/kg/min', label: 'ml/kg/min' },
                                ],
                            },
                            value: 10,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Breathing Rate',
                            img: 'respiration-rate.svg',
                            title: 'Breathing Rate',
                            subtext: 'What\'s your breathing rate today?',
                            unit: {
                                mode: 'single',
                                value: 'bpm',
                                options: [
                                    { value: 'bpm', label: 'bpm' },
                                ],
                            },
                            value: 10,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Pulse Wave Velocity',
                            img: 'heart-rate-variability.svg',
                            title: 'Pulse Wave Velocity',
                            subtext: 'What\'s your pulse wave velocity today?',
                            unit: {
                                mode: 'single',
                                value: 'pwv',
                                options: [
                                    { value: 'pwv', label: 'pwv' },
                                ],
                            },
                            value: 10,
                            has_notes: 1,
                            notes: '',
                        },
                        {
                            name: 'Recovery',
                            img: 'recovery.svg',
                            title: 'Recovery',
                            subtext: 'What\'s your recovery scale',
                            unit: {
                                mode: 'single',
                                value: '%',
                                options: [
                                    { value: '%', label: 'Percentage' }
                                ],
                            },
                            min_val: 0,
                            max_val: 100,
                            value: 10,
                            low_text: 'Low',
                            high_text: 'Superb',
                            has_notes: 1,
                            notes: '',
                        },
                    ]
                },
            ]
        }
    },

    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],

    methods:{
        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },
        open_subitems(e){
            let ev = e.currentTarget.closest('tbody');
            if(ev.classList.contains('show')){
                ev.classList.remove('show');
            }else{
                ev.classList.add('show');
            }
        },
        selectTag(tag, cat){
            const has_item = cat.selected_items.findIndex((att) => att === tag);

            if(has_item == -1){
                cat.selected_items.push(tag);
            } else{
                cat.selected_items.splice(has_item, 1);
            }
        },
        openNotes(ev){
            let showElm = ev.currentTarget.closest('.notes');

            if(showElm.classList.contains('active')){
                showElm.classList.remove('active');
            }else{
                showElm.classList.add('active');
            }

        },
    }
}
</script>

<style scoped>
.preview_area{
    background: #fafafb;
}
.preview_area .close_btn {
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width:25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}
.container{
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
}
.section_title h1{
    font-size: 30px;
    line-height: 47px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
}
.section_title h4 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    color: #5a5a5a;
}
/* card css */
.card_list{
    display: flex;
    flex-wrap: wrap;
    margin: 20px -15px;
}
.card_list > li{
    width: 33.333%;
    padding: 15px;
}
.water_card{
    background: #fff;
    border-radius: 8px;
    text-align: center;
    height: 100%;
}
.top_area{
    padding: 20px 20px 30px 20px;
    background: var(--var-card-bg-color);
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bottom_area{
    padding: 20px 20px 10px 20px;
    position: relative;
}
.bottom_area:before{
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    background: url('~@/assets/images/curve_bg.svg');
    background-size: contain;
}
.water_card img{
    max-height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 10px auto;
}
.water_card .main_title{
    font-size: 18px;
    line-height: 25px;
    color: var(--var-card-text-color);
    font-weight: 500;
    margin-bottom: 10px;
}
.water_card .sub_text{
    font-size: 13px;
    line-height: 20px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}
.water_card .result{
    font-size: 20px;
    line-height: 23px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
    margin-bottom: 15px;
}
.water_card .result span{
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}
.water_card .result_list{
    width: 100%;
    padding-bottom: 15px;
}
.water_card .result_list ul{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    row-gap: 10px;
}
.water_card .result_list li{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;
}
.water_card .result_list li label{
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}
.water_card .result_list li label span{
    background: var(--var-card-elm-color);
    padding: 3px 8px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 10px;
    color: #fff;
    font-weight: 400;
}
.water_card .result_list li .item_result{
    font-size: 20px;
    line-height: 23px;
    color: #121525;
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
}
.water_card .result_list li .item_result span{
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}
.water_card .result_list.sm li .item_result {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
}
.water_card .ratings{
    display: flex;
    gap: 4px;
    padding-bottom: 15px;
}
.water_card .ratings span{
    font-size: 12px;
    color: #999;
}
.water_card .ratings span i.active{
    color: var(--var-card-elm-color);
}
.water_card .single_item{
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}
.water_card .single_item{
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    margin-bottom: 10px;
}
.water_card .multiple_items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-bottom: 10px;
}
.water_card .multiple_items span{
    padding: 4px 8px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}
.range_slider {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
}
.range_slider .range_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 0;
}
.range_slider span {
    height: 6px;
    background: var(--var-card-elm-color);
    position: absolute;
    top: 15px;
    border-radius: 3px;
}
.range_slider span label {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    bottom: 100%;
    padding: 3px 6px;
    background: var(--var-card-elm-color);
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 400;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-bottom: 12px;
}
.range_slider span label:after {
    position: absolute;
    content: '';
    border-top: 4px solid var(--var-card-elm-color);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.range_slider .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: var(--var-card-elm-fade-color);
    outline: none;
    margin: 0;
    position: relative;
}
.range_slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}
.range_slider .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}
.water_card .input_section{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.water_card .input_section .field_box{
    flex: 1 0 33.333%;
    padding: 0 10px 10px 10px;
    display: flex;
    flex-direction: column;
}
.water_card .input_section label{
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    padding: 10px 10px 7px 10px;
}
.water_card .input_section .field_box label{
    padding: 10px 0 7px 0;
}
.water_card .input_section .field_box .field_area{
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}
.water_card .input_section input{
    height: 40px;
    width: 100%;
    padding: 0 10px;
    background: transparent;
}
.water_card .input_section .field_box .unit{
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}
.water_card .check_list{
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}
.water_card .check_list li{
    width: 50%;
    padding: 5px;
}
.water_card .check_list li .checkbox{
    justify-content: flex-start;
}
.water_card .check_list li .checkbox span.checked i{
    opacity: 1;
    transform: scale(1);
}
.water_card .check_list li .checkbox p{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    padding-left: 10px;
}
.water_card .radio_list{
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
}
.water_card .radio_list li{
    width: 50%;
    padding: 10px;
}
.water_card .radio_list li .radio_box{
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 8px;
    padding: 5px 15px 15px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.water_card .radio_list li .radio_box.active{
    border-color: var(--var-card-bg-color);
    background: var(--var-card-bg-color);
}
.water_card .radio_list li .radio_box img{
    height: 50px;
    width: auto;
    margin-bottom: 5px;
}
.water_card .radio_list li .radio_box h5{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
}
.water_card .radio_list li .radio_box p{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}
.water_card .notes{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 25px;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}
.water_card .notes.active{
    max-height: 500px;
}
.water_card .notes .add_notes{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    cursor: pointer;
}
.water_card .notes .add_notes i{
    margin-top: 2px;
    font-size: 8px;
}
.water_card .notes textarea{
    height: 80px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    resize: vertical;
}
.water_card .notes .save_btn{
    padding: 4px 10px;
    border-radius: 3px;
    background: var(--var-card-elm-color);
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin-left: auto;
    cursor: pointer;
}
.water_card table.table_list{
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
    margin-bottom: 15px;
}
.water_card table.table_list tr{
    border-bottom: 1px solid #e9e9e9;
}
.water_card table.table_list th, .water_card table.table_list td {
    padding: 5px 10px;
    text-align: left;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}
.water_card table.table_list th{
    background: #fbfbfb;
}
.water_card table.table_list td{
    background: #fff;
}
.water_card table.table_list td:first-child{
    width: 37px;
}
.water_card table.table_list td:last-child{
    width: 120px;
}
.water_card table.table_list td .field_area{
    gap: 5px;
    display: flex;
    align-items: center;
    position: relative;
}
.water_card table.table_list td input{
    height: 25px;
    width: 100%;
    background: transparent;
}
.water_card table.table_list td .unit svg{
    width: 18px;
    height: 20px;
}
.water_card table.table_list .checkbox span.checked i{
    opacity: 1;
    transform: scale(1);
}
.water_card .body_area{
    margin-bottom: 20px;
    text-align: left;
}
.water_card .body_area table.table_list td:last-child{
    width: 100px;
}
.water_card .body_area table.table_list .field_area.blur{
    opacity: 0.5;
    pointer-events: none;
}
.water_card .pain_area .tabs{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e9e9e9;
}
.water_card .pain_area .tabs li {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
}
.water_card .pain_area .tabs li.active{
    color: var(--var-card-elm-color);
}
.water_card .pain_area .area_section{
    padding-top: 15px;
}
.water_card .pain_area .area_section .pain_item{
    padding: 10px 15px;
    background: #fbfbfb;
    border-radius: 5px;
    margin-bottom: 15px;
}
.water_card .pain_area .area_section .pain_item label.checkbox{
    justify-content: flex-start;
}
label.checkbox span.checked i {
    transform: scale(1);
    opacity: 1;
}
.water_card .pain_area .area_section .pain_item label.checkbox p{
    font-size: 13px;
    line-height: 16px;
    padding-left: 10px;
}
.water_card .pain_area .area_section .pain_item .select_box{
    width: 100%;
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 5px;
    padding: 7px 20px 7px 10px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 25px 0;
    text-align: left;
    position: relative;
}
.water_card .pain_area .area_section .pain_item .select_box p{
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #777;
}
.water_card .pain_area .area_section .pain_item .select_box .tag_wpr{
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}
.water_card .pain_area .area_section .pain_item .select_box .tag{
    background: var(--var-card-elm-color);
    padding: 2px 6px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 3px;
    color: #fff;
    font-weight: 400;
}
.water_card .pain_area .area_section .pain_item .select_box > i{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #999;
}
.water_card .pain_area .area_section .pain_item .select_box .dropdown_wpr{
    z-index: 4;
}

.select_box .dropdown_wpr ul li {
    display: flex;
    gap: 10px;
}
.select_box .dropdown_wpr ul li:last-child{
    border: 0;
    justify-content: flex-start;
}
.select_box .dropdown_wpr ul li .checkbox {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border: 1px solid #BABDC3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 !important;
}
.select_box .dropdown_wpr ul li .checkbox i {
    font-size: 8px;
    line-height: 10px;
    color: #121525;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.select_box .dropdown_wpr ul li .checkbox.checked i{
    transform: scale(1);
    opacity: 1;
}
.water_card .pain_area .area_section .pain_item .result_info{
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    padding: 10px 15px;
    border-radius: 5px;
    background: var(--var-card-bg-color);
    margin: 10px 0;
}

.notification-warning {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #F2A31D;
    margin-top: -5px;
}

/* table css */
.table_list {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 40px;
}
.table_list::-webkit-scrollbar{
    width: 5px;
}
.table_list::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 5px;
    display: none;
}
.table_list:hover::-webkit-scrollbar-thumb{
    display: block;
}
.table_list table{
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border-collapse: collapse;
}
.table_list table thead {
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 3;
}
.table_list table th{
    padding: 12px 15px;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
}
.table_list table tbody tr{
    border-bottom: 1px solid #f5f5f5;
    position: relative;
}
.table_list table td{
    padding: 15px;
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    width: 135px;
}
.table_list table td label.checkbox{
    justify-content: center;
}
.table_list table td h5{
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    text-align: left;
}
.table_list table td.tracker, .table_list table th:first-child {
    background: #fff;
    text-align: left;
    position: sticky;
    left: 0;
}
.tracker .tracker_title{
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    min-width: max-content;
}
.tracker .tracker_title h4{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-direction: column;
}
.tracker .tracker_title img{
    max-height: 22px;
    width: auto;
    padding-right: 10px;
}
.table_list table td .field_area{
    display: flex;
    background: #f9f9f9;
    border-radius: 5px;
}
.table_list table td .field_area input{
    /* flex: 1 1 auto; */
    width: 100%;
    padding: 5px 10px;
    background: transparent;
}
.table_list table td .field_area .unit{
    font-size: 11px;
    line-height: 14px;
    padding: 5px;
    /* background: #e9e9e9; */
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    color: #5a5a5a;
}
.table_list table tbody.accord{
    position: relative;
}
.table_list table tbody.accord tr:first-child{
    position: relative;
    background: #fff;
    z-index: 2;
}
.table_list table tbody.accord tr:first-child .tracker_title{
    position: sticky;
    left: 0;
}
.table_list table tbody.accord tr:first-child .tracker_title h4{
    font-size: 15px;
    line-height: 20px;
}
.table_list table tbody.accord tr:first-child .tracker_title img{
    max-height: 26px;
}
.tracker_title .toggle_btn{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}
.table_list table tbody.accord.show .tracker_title .toggle_btn{
    transform: rotate(-180deg);
}
.table_list table tbody.accord tr.subs{
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}
.table_list table tbody.accord.show tr.subs{
    position: static;
}

.table_list table tbody.accord tr.subs .tracker{
    padding-left: 50px;
}
/* .table_list table tbody.accord tr.subs .tracker:before{
    position: absolute;
    content: '';
    left: 25px;
    top: 15px;
    background-image: url('~@/assets/images/arrow_turn_down.svg');
    background-size: cover;
    width: 18px;
    height: 18px;
} */
</style>